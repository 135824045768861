import React, { useEffect, useState } from 'react';
import './App.css';
import {
    Grid,
    Button,
    Icon,
    Table,
    Image,
    Header,
    TableHeader,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
    Menu, MenuItem,
    MenuMenu,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Input
} from 'semantic-ui-react';

import {
    setFetchMethod,
    enable as enableDarkMode,
    disable as disableDarkMode
} from 'darkreader';
import { Formik } from 'formik';

import { ReadDataExport, GenerateExcelReport, GenerateDataExport } from './ApiInterfaces';
import InputTableRenderer from './InputTableRenderer';
import StandardData from './standard_data.json';
import calculateConformity from './calculateComformity';
import UploadFileFunction from './UploadFileFunction';

function App() {
    setFetchMethod(window.fetch);
    let initialDarkModeValue = false;
    try { initialDarkModeValue = localStorage.getItem("LCC_DarkMode") === "true"; }
    catch { }
    let [darkMode, _setDarkMode] = useState(initialDarkModeValue);

    let initialInputValues = null;
    try { initialInputValues = JSON.parse(localStorage.getItem("LCC_InputValues")); }
    catch { }
    let [inputValues, _setInputValues] = useState(initialInputValues);

    let initialActiveItem = null;
    try { initialActiveItem = JSON.parse(localStorage.getItem("LCC_ActiveItem")); }
    catch { }
    let [activeItem, _setActiveItem] = useState(initialActiveItem);

    let [calculationData, setCalculationData] = useState([]);
    let [tableCalculationData, setTableCalculationData] = useState({});

    useEffect(() => {
        let _calculationData = [];
        StandardData.densityTables.forEach(table => {
            table.allowedPerformanceLevels.forEach(pLevel => {
                _calculationData.push(calculateConformity(inputValues, table.type, pLevel));
            });
        });
        setCalculationData(_calculationData);
    }, [inputValues]);


    useEffect(() => {
        if (activeItem && calculationData && calculationData.length > 0) {
            setTableCalculationData(calculationData.find(x => x.type === activeItem));
        } else {
            setTableCalculationData(null);
        }

    }, [calculationData, activeItem]);

    useEffect(() => {
        console.log("useEffect", darkMode);
        if (darkMode) {
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 0
            });
        } else {
            disableDarkMode();
        }

    }, [darkMode]);

    let setInputValues = (inputValues) => {
        _setInputValues(inputValues);
        localStorage.setItem("LCC_InputValues", JSON.stringify(inputValues));
    };

    let setActiveItem = (activeItem) => {
        _setActiveItem(activeItem);
        localStorage.setItem("LCC_ActiveItem", JSON.stringify(activeItem));
    }

    let setDarkMode = (value) => {
        _setDarkMode(value);
        localStorage.setItem("LCC_DarkMode", value);
    }

    let ResetValues = () => {
        localStorage.removeItem("LCC_InputValues");
        localStorage.removeItem("LCC_ActiveItem");
        window.location.reload();
    }

    let initialValues = {};
    if (inputValues != null) {
        initialValues = inputValues;
    }

    return (
        <>
            <Menu fixed='top'>
                <MenuItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Image src={darkMode ? "logo_dark.png" : "logo.png"} style={{ width: 289, maxWidth: 289 }} />
                </MenuItem>

                <MenuItem icon="upload" name="Dosya Yükle" onClick={() => UploadFileFunction(async (file) => { setInputValues(await ReadDataExport(file)); })} />

                <MenuItem icon="download" name="Dosya İndir" onClick={() => GenerateDataExport(inputValues)} />

                <MenuItem icon="delete" name="Tabloyu Sıfırla" onClick={() => ResetValues(inputValues)} />

                <MenuItem icon="file" name="Rapor Çıkart" onClick={() => GenerateExcelReport(inputValues, calculationData)} />

                <MenuMenu position='right'>
                    <MenuItem>
                        <h4>TS EN 12368:2015 Sinyal Lambası Uyumluluk Hesaplayıcı</h4>
                    </MenuItem>
                    <MenuItem icon={darkMode ? "sun" : "moon"} onClick={() => setDarkMode(!darkMode)} />
                </MenuMenu>
            </Menu>
            <Grid style={{ minWidth: 1400 }}>
                <Grid.Row centered>
                    <Grid.Column width={16} style={{ minWidth: 1000, maxWidth: 1500 }}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validateOnBlur={true}
                            validate={values => {
                                setInputValues(values);
                            }}

                        >{({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (

                            <form onSubmit={handleSubmit}>
                                <Table structured celled style={{ overflow: 'visible' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell width={2} textAlign='right' style={{ fontWeight: "bold" }}>Marka:</TableCell>
                                            <TableCell>
                                                <Input
                                                    fluid
                                                    name="brand"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values["brand"]}
                                                    required
                                                />
                                            </TableCell>
                                            <TableCell width={2} textAlign='right' style={{ fontWeight: "bold" }}>Model:</TableCell>
                                            <TableCell>
                                                <Input
                                                    fluid
                                                    name="model"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values["model"]}
                                                    required
                                                />
                                            </TableCell>
                                            <TableCell width={2} textAlign='right' style={{ fontWeight: "bold" }}>Seri Numarası:</TableCell>
                                            <TableCell>
                                                <Input
                                                    fluid
                                                    name="serial"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values["serial"]}
                                                    required
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </form>
                        )}
                        </Formik>

                        <Table definition structured celled style={{ overflow: 'visible' }}>
                            <TableHeader fullWidth={true}>
                                <TableRow>
                                    <TableHeaderCell width={2}>Açısal Dağılım</TableHeaderCell>
                                    <TableHeaderCell colSpan={10}>Işık Performans Seviyesi</TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {StandardData.densityTables.map(table => (
                                    <TableRow key={table.type}>
                                        <TableCell key={table.type + "_desc"}>Tip {table.type} ({table.description})</TableCell>
                                        {Array.from({ length: 10 }).map((_, index) => {
                                            if (typeof table.allowedPerformanceLevels[index] === "undefined") return (<TableCell key={table.type + "_" + index}></TableCell>);

                                            let cellCodeVariant = "yellow";
                                            let cellPerfLevel = table.allowedPerformanceLevels[index];
                                            let cellTypeCode = table.type + "_" + table.allowedPerformanceLevels[index];
                                            let isActive = activeItem == (table.type + "_" + table.allowedPerformanceLevels[index]);
                                            if (calculationData.length > 0) {
                                                let cellCalcData = calculationData.find(x => x && x.type === cellTypeCode);
                                                if (cellCalcData && cellCalcData?.isGood) cellCodeVariant = "green";
                                                if (cellCalcData && !cellCalcData?.isGood) cellCodeVariant = "red";
                                            }

                                            return (
                                                <TableCell key={table.type + "_" + index} style={{ textAlign: 'center', padding: 5 }}>
                                                    {table.allowedPerformanceLevels[index] ?
                                                        <Button fluid basic={!isActive} color={cellCodeVariant}
                                                            onClick={
                                                                () => {
                                                                    if (isActive)
                                                                        setActiveItem(null);
                                                                    else
                                                                        setActiveItem(cellTypeCode)
                                                                }
                                                            }
                                                            active={isActive}

                                                        >{cellPerfLevel}</Button>
                                                        : null}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                    <Grid.Column width={16} style={{ minWidth: 1000, maxWidth: 1500 }}>
                        <InputTableRenderer calculationData={tableCalculationData} inputValues={inputValues} setInputValues={setInputValues} />
                    </Grid.Column>
                </Grid.Row>


                {/* <Grid.Row centered>
                <Grid.Column width={6}>
                    
                    <pre>
                        {JSON.stringify(inputValues, null, "\t")}
                    </pre>
                </Grid.Column>
                <Grid.Column width={6}>
                    <pre>
                        {JSON.stringify(calculationData, null, "\t")}
                    </pre>
                </Grid.Column>
            </Grid.Row> */}
            </Grid>

        </>
    );
}


export default App;
