import axios from 'axios';

export async function ReadDataExport(file) {
    let form_data = new FormData();
    form_data.append("file", file);

    let res = await axios.post(window.location.origin + '/api/read-data-export', form_data);
    if (res.status === 200) {
        return res.data;
    } else {
        return null;
    }
    return null;
}

export async function GenerateDataExport(inputValues) {

    if(
        inputValues.brand == undefined || 
        inputValues.model == undefined || 
        inputValues.serial == undefined || 
        inputValues.brand === "" || 
        inputValues.model === "" || 
        inputValues.serial === ""){
        alert("Lütfen Marka, model ve seri numarası bilgilerini giriniz.");
        return;
    }

    let fileName = prompt("Dosya Adını Belirleyin", `Olcum_Degerleri_${inputValues.brand}_${inputValues.model}_${inputValues.serial}`)
    if(!fileName) return;

    axios({
        url: window.location.origin + '/api/generate-data-export',
        method: 'POST',
        responseType: 'blob',
        data: {
            inputValues
        }
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        link.setAttribute('download', `${fileName}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
    .catch((err) => {
        console.log(err);
    });
}


export async function GenerateExcelReport(inputValues, calculationData) {
    if(
        inputValues.brand == undefined || 
        inputValues.model == undefined || 
        inputValues.serial == undefined || 
        inputValues.brand === "" || 
        inputValues.model === "" || 
        inputValues.serial === ""){
        alert("Lütfen Marka, model ve seri numarası bilgilerini giriniz.");
        return;
    }
    
    let fileName = prompt("Dosya Adını Belirleyin", `Kapsamlı_Rapor_${inputValues.brand}_${inputValues.model}_${inputValues.serial}`)
    if(!fileName) return;

    axios({
        url: window.location.origin + '/api/generate-excel-report',
        method: 'POST',
        responseType: 'blob',
        data: {
            inputValues,
            calculationData
        }
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const date = new Date();
        link.setAttribute('download', `${fileName}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
    })
    .catch((err) => {
        console.log(err);
    });
}